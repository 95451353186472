import useInitUser from '@/hooks/useInitUser';
import { useModel } from '@umijs/max';
import { useEffect } from 'react';

const FetchListingContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getCampaignStatus } = useModel('useCampaign');
  useInitUser();

  useEffect(() => {
    getCampaignStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default FetchListingContainer;
