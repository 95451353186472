import { serviceName } from '@/utils/constant';
import userInfoManager from '@/utils/userInfoManager';

declare global {
  interface Window {
    analytics: {
      page: (name: string) => void;
      track: (name: string, obj: Record<string, any>) => void;
      identify: (userId: string, obj: Record<string, any>) => void;
    };
    dataLayer: any;
  }
}

export const getCommonVariables = () => ({ serviceName, ...userInfoManager.getUser() });
