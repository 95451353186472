import type React from 'react';
import { useEffect } from 'react';

import useHistoryChange from '@/hooks/useHistoryChange';
import initializeTagmanager from '@/tracking/gtm/initTagmanager.init';
import initializeHotjar from '@/tracking/hotjar/init';
import initializeSegment from '@/tracking/segment/initSegment.init';

const TrackingInit: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  useEffect(() => {
    initializeTagmanager();
    initializeSegment();
    initializeHotjar();
  }, []);
  useHistoryChange();
  return children;
};

export default TrackingInit;
