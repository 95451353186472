import { RocketOutlined } from '@ant-design/icons';
import { useIntl } from '@umijs/max';
import { Button } from 'antd';
import { createPortal } from 'react-dom';
import { GlobalFixedLayoutStyle, StyledAlert } from './styles';

const AlertWrapper: React.FC<{ onDisplayModal: () => void }> = ({ onDisplayModal }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <GlobalFixedLayoutStyle />
      {createPortal(
        <StyledAlert
          showIcon
          icon={<RocketOutlined style={{ fontSize: '24px' }} />}
          message={formatMessage({
            id: 'demo.navbar.title',
            defaultMessage: 'Welcome to Genie AI Demo Mode',
          })}
          type="info"
          action={
            <Button onClick={onDisplayModal} size="middle" type="primary">
              {formatMessage({ id: 'demo.navbar.action.exit', defaultMessage: 'Exit Demo' })}
            </Button>
          }
        />,
        document.body,
      )}
    </>
  );
};

export default AlertWrapper;
