/* eslint-disable @typescript-eslint/no-unused-expressions */
import { getCommonVariables } from '@/tracking/commonVariables';
import { pushDataLayer } from '@/tracking/gtm/initEvent';

// reset segment track info
const pushResetSession = () => {
  pushDataLayer({ event: 'logout', ...getCommonVariables() });
};

const pushIdentifyUserLogin = () => {
  pushDataLayer({ event: 'login', ...getCommonVariables() });
};

export { pushIdentifyUserLogin, pushResetSession };
