import { getCommonVariables } from '@/tracking/commonVariables';
import { page, track } from './functions';

// event
export const pushEventPageView = (projectName: string) => {
  page(projectName);
};

export const pushEventVisitSupportPage = () => {
  track('Visited Customer Support Page', {
    ...getCommonVariables(),
  });
};

export const pushEventVisitFQAPage = () => {
  track('Visited FAQ Page', { ...getCommonVariables() });
};

// User click the date picker and submit a new data range
export const trackChangeDateRange = (date: string, pageName: string) => {
  track(`Change Date Range_${pageName}`, { date, ...getCommonVariables() });
};
