import {
  getAudienceApi,
  getChannelGroupApi,
  getEngagementRateAPI,
  getOverviewAPI,
  getPageViewAPI,
  getSessionAPI,
  getSourceMediumApi,
} from '@/services/performance';
import { audienceNameField } from '@/utils/constant';
import { useState } from 'react';

export default () => {
  const [overviewData, setOverviewData] = useState<API.Overview>();
  const [overviewTable, setOverviewTable] = useState<API.ChannelGroup | API.SourceMedium>();
  const [pageView, setPageView] = useState<API.PageView>();
  const [session, setSession] = useState<API.Session>();
  const [engagementRate, setEngagementRate] = useState<API.EngagementRate>();
  const [audienceNewUser, setAudienceNewUser] = useState<API.Audience>();
  const [audienceReturningUser, setAudienceReturningUser] = useState<API.Audience>();

  const getOverview = async (params: API.OverviewGetParameters, options?: API.RequestOptions) => {
    return getOverviewAPI(params, options).then((res) => {
      setOverviewData(res);
      return res;
    });
  };

  const getTable = async (
    params: API.ChannelGroupGetParameters | API.SourceMediumGetParameters,
    options?: API.RequestOptions,
    type?: 'channelGroup' | 'sourceMedium',
  ) => {
    const selectFunc = type === 'channelGroup' ? getChannelGroupApi : getSourceMediumApi;
    return selectFunc(params as API.ChannelGroupGetParameters, options).then((res) => {
      setOverviewTable(res);
      return res;
    });
  };

  const getPageView = async (params: API.PageViewGetParameters, options?: API.RequestOptions) => {
    return getPageViewAPI(params, options).then((res) => {
      setPageView(res);
      return res;
    });
  };

  // getSessionAPI
  const getSession = async (params: API.SessionGetParameter, options?: API.RequestOptions) => {
    return getSessionAPI(params, options).then((res) => {
      setSession(res);
      return res;
    });
  };

  const getEngagementRate = async (
    params: API.EngagementRateGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getEngagementRateAPI(params, options).then((res) => {
      setEngagementRate(res);
      return res;
    });
  };

  const getAudienceNewUser = async (
    params: Omit<API.AudienceGetParameters, 'audienceName'>,
    options?: API.RequestOptions,
  ) => {
    return getAudienceApi(
      { ...params, audienceName: audienceNameField.mobagelNewUser },
      options,
    ).then((res) => {
      setAudienceNewUser(res);
      return res;
    });
  };

  const getAudienceReturningUser = async (
    params: Omit<API.AudienceGetParameters, 'audienceName'>,
    options?: API.RequestOptions,
  ) => {
    return getAudienceApi(
      { ...params, audienceName: audienceNameField.mobagelReturningUser },
      options,
    ).then((res) => {
      setAudienceReturningUser(res);
      return res;
    });
  };

  return {
    getOverview,
    overviewData,
    getTable,
    overviewTable,
    getPageView,
    pageView,
    getSession,
    session,
    engagementRate,
    getEngagementRate,
    getAudienceNewUser,
    audienceNewUser,
    getAudienceReturningUser,
    audienceReturningUser,
  };
};
