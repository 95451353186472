export const serviceName = '8ndpoint2';

export const DEFAULT_LANGS = [
  {
    lang: 'en-US',
    label: 'English',
  },
  {
    lang: 'zh-TW',
    label: '繁體中文',
  },
  {
    lang: 'zh-CN',
    label: '简体中文',
  },
];

export const mediaTypes = ['google', 'facebook'] as const;
export type MediaArray = typeof mediaTypes;
export type Media = MediaArray[number];

export const weekdays = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
} as const;
export type WEEKDAY = (typeof weekdays)[keyof typeof weekdays];

export const attributionModelType = [
  'adsManager',
  'firstTouch',
  'lastTouch',
  'anyTouch',
  'timeDecay',
  'linear',
] as const;
export type AttributionModelTypeArray = typeof attributionModelType;
export type AttributionModelType = AttributionModelTypeArray[number];

export const mediaMetricsType = ['adSpend', 'users', 'newUsers', 'purchases', 'revenue'] as const;
export type MediaMetricsTypeArray = typeof mediaMetricsType;
export type MediaMetricsType = MediaMetricsTypeArray[number];

export const statementGroupType = ['active_user', 'referrer', 'event_count'] as const;
export type StatementGroupTypeArray = typeof statementGroupType;
export type StatementGroupType = StatementGroupTypeArray[number];

export const activeUserFields = {
  active7d: 'active_7days',
  active14d: 'active_14days',
  active28d: 'active_28days',
  activeAny: null,
} as const;
export type ActiveUserFields = (typeof activeUserFields)[keyof typeof activeUserFields];

export const referrerFields = {
  sourceMediumPath: 'source_medium_path',
  campaignPath: 'campaign_path',
} as const;
export type ReferrerFields = (typeof referrerFields)[keyof typeof referrerFields];

export const eventFields = {
  eventCountViewItem: 'event_count_view_item',
  eventCountAddToCart: 'event_count_add_to_cart',
  eventCountViewCart: 'event_count_view_cart',
  eventCountBegintCheckout: 'event_count_begint_checkout',
  eventCountPurchase: 'event_count_purchase',
} as const;
export type EventFields = (typeof eventFields)[keyof typeof eventFields];

export const trafficMetricFields = {
  users: 'users',
  newUsers: 'new_users',
  conversions: 'conversions',
  conversionRate: 'conversion_rate',
  engagementRate: 'engagement_rate',
  revenue: 'revenue',
} as const;
export type TrafficMetric = (typeof trafficMetricFields)[keyof typeof trafficMetricFields];

export const gaSessionMetricField = {
  sessions: 'sessions',
  engagementRate: 'engagementRate',
  averageEngagementTimePerSession: 'averageEngagementTimePerSession',
};
export type GaSessionMetrics = (typeof gaSessionMetricField)[keyof typeof gaSessionMetricField];

export const audienceNameField = {
  mobagelNewUser: 'mobagel-new-user',
  mobagelReturningUser: 'mobagel-returning-user',
};
export type AudienceNameField = (typeof audienceNameField)[keyof typeof audienceNameField];

export const dimensionField = {
  sessionSourceMedium: 'sessionSourceMedium',
  sessionCampaignName: 'sessionCampaignName',
};
export type DimensionField = (typeof dimensionField)[keyof typeof dimensionField];

export const sourceMediumMetrics = {
  sessions: 'sessions',
  newUsersInTotal: 'new_users_in_total',
};
export type SourceMediumMetrics = (typeof sourceMediumMetrics)[keyof typeof sourceMediumMetrics];

export const productTableMetricsField = {
  purchases: 'purchases',
  itemViews: 'item_views',
};
export type ProductTableMetricsField =
  (typeof productTableMetricsField)[keyof typeof productTableMetricsField];
