// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import AppstoreFilled from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/AppstoreFilled';
import FunnelPlotFilled from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FunnelPlotFilled';
import StockOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/StockOutlined';
import BorderLeftOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BorderLeftOutlined';
import SettingFilled from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SettingFilled';
import DatabaseFilled from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/DatabaseFilled';
export default { AppstoreFilled, FunnelPlotFilled, StockOutlined, BorderLeftOutlined, SettingFilled, DatabaseFilled };
