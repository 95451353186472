import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import { history } from 'umi';

import RightContent from '@/components/RightContent';
import TutorialMode from '@/components/TutorialMode';

import layoutSettings from '../config/layout';
import themeSettings, { advanceTheme } from '../config/theme';
// @ts-ignore
import { getUserApi } from '@/services/user';

import { ThemeProvider } from 'styled-components';
import { request } from './services/interceptor';

import FetchListingContainer from './components/FetchListingContainer';
import TrackingInit from './tracking/TrackingInit';

import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';

import { setupCompanyId } from '@/utils/localStorage';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { GlobalStyle } from './Global.style';

if (SENTRY_DSN_SERVER) {
  Sentry.init({
    dsn: SENTRY_DSN_SERVER,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: GOOGLE_CLOUD_PROJECT,
  });
}

// can view these pages without sso token
const unprotectedPages = ['/login', '/signup', '/resetpassword', '/report'];

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.User;
  collapsed?: boolean;
  fetchUserInfo?: () => Promise<API.User | undefined>;
}> {
  const fetchUserInfo = async () => {
    return getUserApi()
      .then((res) => {
        setupCompanyId(res as API.User);
        return res;
      })
      .catch(() => {
        return undefined;
      });
  };

  const { pathname } = history.location;

  if (!unprotectedPages.includes(pathname)) {
    const currentUser = await fetchUserInfo();
    return {
      currentUser,
      settings: layoutSettings || {},
      collapsed: false,
    };
  }

  return {
    settings: layoutSettings || {},
  };
}

export function rootContainer(container: React.ReactNode) {
  return (
    // useModel is not exist when component running on rootContainer
    <TrackingInit>
      <ConfigProvider theme={themeSettings}>
        <ThemeProvider theme={{ advanceTheme, ...themeSettings }}>
          <>
            <GlobalStyle />
            {container}
          </>
        </ThemeProvider>
      </ConfigProvider>
    </TrackingInit>
  );
}

export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  const { collapsed = false } = initialState;
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    onPageChange: () => {
      window.scrollTo({
        top: 0,
      });
      const { pathname } = history.location;
      if (!unprotectedPages.includes(pathname) && !initialState?.currentUser?.id) {
        getUserApi().catch((err) => console.log(err));
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onCollapse: (collapsed) => setInitialState({ ...initialState, collapsed }),
    childrenRender: (children) => {
      return (
        <FetchListingContainer>
          <TutorialMode />
          {children}
        </FetchListingContainer>
      );
    },
    collapsed,
    // force change the menu type when collapsed changed
    menu: collapsed
      ? {
          type: 'sub',
        }
      : { type: 'group' },
    // custom 403 page
    unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};

export { request };
