import { useModel } from '@umijs/max';
import { useEffect, useState } from 'react';
import AlertWrapper from './AlertWrapper';
import TutorialModal from './TutorialModal';

const Index = () => {
  const [open, setOpen] = useState(false);
  const currentUser = useModel('@@initialState')?.initialState?.currentUser;
  const demoMode = currentUser?.email === 'demo@mobagel.com';

  useEffect(() => {
    if (currentUser) {
      // reload to demo mode when user doesn't setup ga account
      if (demoMode && sessionStorage.getItem('mode') !== 'demo') {
        sessionStorage.setItem('mode', 'demo');
        window.location.reload();
        // remove sessionStorage when user setup ga account
      } else if (!demoMode && sessionStorage.getItem('mode') === 'demo') {
        sessionStorage.removeItem('mode');
        window.location.reload();
      }
    }
  }, [demoMode, currentUser]);

  if (currentUser && demoMode) {
    return (
      <div>
        <TutorialModal open={open} onConfirm={() => setOpen(false)} />
        {open === false && <AlertWrapper onDisplayModal={() => setOpen(true)} />}
      </div>
    );
  }
  return null;
};

export default Index;
