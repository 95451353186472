import { getProductAllAPI, getProductListAPI } from '@/services/product';
import { useState } from 'react';

export default () => {
  const [productAll, setProductAll] = useState<API.ProductAll>();
  const [productList, setProductList] = useState<API.ProductList>();

  const getProductAll = async (
    params: API.ProductAllGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getProductAllAPI(params, options).then((res) => {
      // filter out empty product name
      const { data } = res || {};
      const filterEmptyName = data?.filter((item) => item?.name !== '');
      setProductAll({ ...res, data: filterEmptyName });
      return res;
    });
  };

  const getProductList = async (
    params: API.ProductListGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getProductListAPI(params, options).then((res) => {
      setProductList(res);
      return res;
    });
  };

  return {
    productAll,
    getProductAll,
    productList,
    getProductList,
  };
};
