import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { getStorageCompanyId } from '@/utils/localStorage';
import { request } from 'umi';

export const getProductAllAPI = async (
  params: API.ProductAllGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.ProductAll>('/product/all', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getProductListAPI = async (
  params: API.ProductListGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.ProductList>('/product/list', {
    params: { ...params, companyId },
    ...options,
  });
};
