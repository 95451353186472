import { getStorageCompanyId } from '@/utils/localStorage';

interface Access {
  isDemo: boolean;
  canReadAia: boolean;
  canReadGenie: boolean;
  canReadTracking: boolean;
}

type InitialStateType = {
  collapsed: boolean;
  currentUser?: API.CurrentUser;
};

export default function access(initialState: InitialStateType): Access {
  const isDemo = window.location.hostname.startsWith('demo');
  const selectCompanyId = getStorageCompanyId();
  const selectCompany = initialState?.currentUser?.companies?.find(
    (company) => company.id === selectCompanyId,
  );
  const { useAia = true, useGenie = true } = selectCompany || {};

  return {
    isDemo,
    canReadAia: useAia,
    canReadGenie: useGenie,
    canReadTracking: useAia || useGenie,
  };
}
