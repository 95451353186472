import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { getStorageCompanyId } from '@/utils/localStorage';
import { request } from 'umi';

export const getTrafficQualityMetrics = async (options?: API.RequestOptions) => {
  const companyId = getStorageCompanyId();
  return request<API.TrafficQualityMetrics>('/traffic_quality/metrics', {
    params: { companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getTrafficQualityReport = async (
  params: API.TrafficQualityReportGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.TrafficQualityReport>('/traffic_quality/all', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export async function downloadTrafficQualityReport(
  params: API.TrafficQualityReportDownloadParametersType,
) {
  const companyId = getStorageCompanyId();
  return request<API.DownloadType>('/traffic_quality/download', {
    responseType: 'blob',
    params: { ...params, companyId },
    skipErrorMessage: true,
  });
}

export const getTrafficQualityMeta = async (options?: API.RequestOptions) => {
  const companyId = getStorageCompanyId();
  return request<API.TrafficQualityMeta>('/traffic_quality/meta', {
    params: { companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};
