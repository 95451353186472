import { getUserApi, putUserApi } from '@/services/user';
import { getLandingPage } from '@/utils/getSsoUrl';
import { getStorageCompanyId, setupCompanyId } from '@/utils/localStorage';
import { history, useModel } from 'umi';

export default () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const fetchUser = async () => {
    return getUserApi()
      .then(async (user) => {
        if (user) {
          setInitialState({
            ...initialState,
            currentUser: user,
          });
        }
        return user;
      })
      .catch((err) => console.log(err));
  };

  const updateUser = async (params: API.PutUserPersonalParams | API.PutUserPasswordParams) =>
    putUserApi(params).then(async (user) => {
      if (user) {
        setInitialState({
          ...initialState,
          currentUser: user,
        });
      }
    });

  const loginOnSuccess = () => {
    fetchUser().then((res) => {
      setupCompanyId(res as API.User);
      const selectCompanyId = getStorageCompanyId() || res?.companies[0]?.id;
      const { useAia, useGenie } =
        res?.companies.find((company) => company?.id === selectCompanyId) || {};
      history.push(getLandingPage({ useAia, useGenie }));
    });
  };

  return {
    fetchUser,
    loginOnSuccess,
    updateUser,
  };
};
