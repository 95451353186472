import {
  getCampaignAllAPI,
  getCampaignDetailApi,
  getCampaignDetailChartApi,
  getCampaignListApi,
  getCampaignMonthlyAPI,
  getCampaignNewUsersAPI,
  getCampaignPurchasesAPI,
  getCampaignSpendingAPI,
  getCampaignStatusAPI,
} from '@/services/campaign';
import { useState } from 'react';

export default () => {
  const [campaignList, setCampaignList] = useState<API.CampaignList>([]);
  const [campaignDetail, setCamapaignDetail] = useState<API.CampaignDetail>();
  const [campaignDetailChart, setCamapaignDetailChart] = useState<API.CampaignDetailMetricsChart>();
  const [campaignSpending, setCampaignSpending] = useState<API.CampaignSpending>([]);
  const [campaignPurchases, setCampaignPurchases] = useState<API.CampaignPurchases>([]);
  const [campaignNewUsers, setCampaignNewUsers] = useState<API.CampaignNewUsers>([]);
  const [campaignAll, setCampaignAll] = useState<API.CampaignAll>();
  const [campaignMonthly, setCampaignMonthly] = useState<API.CampaignMonthly>();
  const [campaignStatus, setCampaignStatus] = useState<API.CampaignStatus>();

  const getCampaignList = async (
    params: API.CampaignListGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignListApi(params, options).then((res) => {
      setCampaignList(res);
      return res;
    });
  };

  const getCampaignDetail = async (
    params: API.CampaignDetailGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignDetailApi(params, options).then((res) => {
      setCamapaignDetail(res);
      return res;
    });
  };

  const getCampaignDetailChart = async (
    params: API.CampaignDetailMetricsChartGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignDetailChartApi(params, options).then((res) => {
      setCamapaignDetailChart(res);
      return res;
    });
  };

  const getCampaignSpending = async (
    params: API.CampaignSpendingGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignSpendingAPI(params, options).then((res) => {
      setCampaignSpending(res);
      return res;
    });
  };

  const getCampaignPurchases = async (
    params: API.CampaignPurchasesGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignPurchasesAPI(params, options).then((res) => {
      setCampaignPurchases(res);
      return res;
    });
  };

  const getCampaignNewUsers = async (
    params: API.CampaignNewUsersGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignNewUsersAPI(params, options).then((res) => {
      setCampaignNewUsers(res);
      return res;
    });
  };

  const getCampaignAll = async (
    params: API.CampaignAllGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignAllAPI(params, options).then((res) => {
      setCampaignAll(res);
      return res;
    });
  };

  const getCampaignMonthly = async (
    params: API.CampaignMonthlyGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getCampaignMonthlyAPI(params, options).then((res) => {
      setCampaignMonthly(res);
      return res;
    });
  };

  const getCampaignStatus = async (options?: API.RequestOptions) => {
    return getCampaignStatusAPI(options).then((res) => {
      setCampaignStatus(res);
      return res;
    });
  };

  return {
    campaignList,
    getCampaignList,
    campaignDetail,
    getCampaignDetail,
    campaignDetailChart,
    getCampaignDetailChart,
    getCampaignSpending,
    campaignSpending,
    getCampaignPurchases,
    campaignPurchases,
    getCampaignNewUsers,
    campaignNewUsers,
    getCampaignAll,
    campaignAll,
    getCampaignMonthly,
    campaignMonthly,
    campaignStatus,
    getCampaignStatus,
  };
};
