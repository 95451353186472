import { getCompanyAPI } from '@/services/company';
import { useState } from 'react';

export default () => {
  const [company, setCompany] = useState<API.Company>();

  const getCompany = async (options?: API.RequestOptions) => {
    return getCompanyAPI(options).then((res) => {
      setCompany(res);
      return res;
    });
  };

  const updateCompanyItem = async ({
    tracking,
    metaAds,
    ga4,
  }: {
    tracking?: API.Tracking;
    metaAds?: API.MetaAds;
    ga4?: API.Ga4;
  }) => {
    return setCompany((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        tracking: tracking !== undefined ? tracking : prev.tracking,
        metaAds: metaAds !== undefined ? metaAds : prev.metaAds,
        ga4: ga4 !== undefined ? ga4 : prev.ga4,
      };
    });
  };

  return {
    company,
    getCompany,
    updateCompanyItem,
  };
};
