import useLogout from '@/hooks/useLogout';
import { Space } from 'antd';
import React from 'react';
import { setLocale, useIntl, useModel } from 'umi';
import InfoDropDwon from './InfoDrowdown';
import LangDrowdown from './LangDrowdown';
import UserDrowdown from './UserDrowdown';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  const { formatMessage } = useIntl();
  const logout = useLogout();

  if (!initialState || !initialState.currentUser) {
    return null;
  }

  return (
    <Space size={16} style={{ paddingRight: '32px' }}>
      <LangDrowdown onUpdateLocale={setLocale} />
      <UserDrowdown
        onUserlogout={logout}
        formatMessage={formatMessage}
        currentUser={{
          email: initialState?.currentUser?.email || '',
        }}
        companies={initialState?.currentUser?.companies ? initialState?.currentUser?.companies : []}
      />
      <InfoDropDwon formatMessage={formatMessage} />
    </Space>
  );
};
export default GlobalHeaderRight;
