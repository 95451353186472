import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { getStorageCompanyId } from '@/utils/localStorage';
import { request } from 'umi';

export const getOverviewAPI = async (
  params: API.RevenueOverviewGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenueOverview>('/revenue/overview', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getPurchaseAPI = async (
  params: API.RevenuePurchaseGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenuePurchase>('/revenue/purchases', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getAvgPurchaseRevenueAPI = async (
  params: API.RevenueAvgPurchaseRevenueGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenueAvgPurchaseRevenue>('/revenue/avg_purchase_revenue', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getItemPurchasedAPI = async (
  params: API.RevenueItemPurchasedGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenueItemPurchased>('/revenue/item_purchased', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getSourceMediumAPI = async (
  params: API.RevenueSourceMediumGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenueSourceMedium>('/revenue/source_medium', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getItemBrandAPI = async (
  params: API.RevenueItemBrandGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenueItemBrand>('/revenue/item_brand', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};

export const getProductAPI = async (
  params: API.RevenueProductGetParameters,
  options?: API.RequestOptions,
) => {
  const companyId = getStorageCompanyId();
  return request<API.RevenueProduct>('/revenue/product', {
    params: { ...params, companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};
