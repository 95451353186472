import { request } from 'umi';

export async function getUserApi() {
  return request<API.User>('/user/me');
}

export async function putUserApi(params: API.PutUserPersonalParams | API.PutUserPasswordParams) {
  return request<API.User>('/user/me', {
    method: 'put',
    data: params,
  });
}
