import { getRequestOptionDemoMode } from '@/utils/demoMode';
import { getStorageCompanyId } from '@/utils/localStorage';
import { request } from 'umi';

export const getCompanyAPI = async (options?: API.RequestOptions) => {
  const companyId = getStorageCompanyId();
  return request<API.Company>('/company', {
    params: { id: companyId },
    ...options,
    ...getRequestOptionDemoMode(),
  });
};
