import { getLandingPage } from '@/utils/getSsoUrl';
import { getStorageCompanyId, setStorageCompanyId } from '@/utils/localStorage';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import React from 'react';

interface UserlogoutProps {
  (): Promise<boolean | void>;
}

interface FormatMessageProp {
  ({ id }: { id: string }): string;
}

export type GlobalHeaderRightProps = {
  onUserlogout: UserlogoutProps;
  formatMessage: FormatMessageProp;
  currentUser: {
    email: string;
  };
  companies: API.Company[];
};

const UserDrowdown: React.FC<GlobalHeaderRightProps> = ({
  onUserlogout,
  formatMessage,
  currentUser,
  companies,
}) => {
  const selectCompanyId = getStorageCompanyId() || companies[0].id;
  const selectCompany = companies.find((company) => company.id === selectCompanyId);
  const optionCompanied = companies.filter((company) => company?.id !== selectCompanyId);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const loginOut: (onUserlogout: UserlogoutProps) => Promise<void> = async (onUserlogout) => {
    const isDisableRedirect = await onUserlogout();
    if (!isDisableRedirect) {
      window.location.href = `/login`;
    }
  };

  const companyMenu = {
    key: 'company',
    type: 'group',
    label: 'Company',
    children: optionCompanied.map((company) => ({
      key: company.id,
      label: (
        <div
          onClick={() => {
            setStorageCompanyId(company.id);
            const { useAia, useGenie } = company;
            const getLangPage = getLandingPage({ useAia, useGenie });
            window.location.href = getLangPage;
          }}
        >
          <UserOutlined style={{ marginRight: '4px' }} />
          {company.name}
        </div>
      ),
    })),
  };

  const items: MenuProps['items'] = [
    optionCompanied?.length ? companyMenu : null,
    {
      key: 'user',
      type: 'group',
      label: currentUser?.email,
      children: [
        {
          key: 'user-logout',
          label: (
            <div onClick={() => loginOut(onUserlogout)}>
              <Space size={6}>
                <LogoutOutlined />
                {formatMessage({
                  id: 'common.nav.logout',
                })}
              </Space>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Dropdown overlayClassName="user-dropdown" menu={{ items }} placement="bottomRight">
      <span style={{ cursor: 'pointer', padding: '16px 0' }}>
        <UserOutlined style={{ marginRight: '4px' }} />
        {selectCompany?.name}
      </span>
    </Dropdown>
  );
};

export default UserDrowdown;
