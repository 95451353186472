import { decamelizeKeys } from 'humps';

export function track(name: string, obj: Record<string, any>): void {
  if (typeof window.analytics?.track === 'function') {
    window.analytics.track(name, decamelizeKeys(obj));
  }
}

export function page(name: string): void {
  if (typeof window.analytics?.page === 'function') {
    window.analytics.page(name);
  }
}

export function identify(userId: string, obj: Record<string, any>): void {
  if (typeof window.analytics?.identify === 'function') {
    window.analytics.identify(userId, decamelizeKeys(obj));
  }
}
