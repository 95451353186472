import { RocketOutlined } from '@ant-design/icons';
import { useIntl } from '@umijs/max';
import { Button, Modal, Space, Typography } from 'antd';
import logo from '../../../public/nav_logo.png';
import {
  GlobalFilterBlurStyle,
  StyleTitle,
  StyledDivier,
  StyledIcon,
  StyledLinkTextWrapper,
} from './styles';

const { Text } = Typography;

const TutorialModal: React.FC<{ open: boolean; onConfirm: () => void }> = ({ open, onConfirm }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {open === true && <GlobalFilterBlurStyle />}
      <Modal
        closeIcon={null}
        title={<img style={{ maxWidth: '100px' }} src={logo} />}
        centered
        open={open}
        footer={null}
        width={560}
      >
        <div style={{ textAlign: 'center' }}>
          <StyledDivier />
          <Space direction="vertical" size={16}>
            <StyledIcon>
              <RocketOutlined style={{ fontSize: '72px' }} />
            </StyledIcon>
            <StyleTitle level={3}>
              {formatMessage({
                id: 'demo.modal.title',
                defaultMessage: 'Welcome to Our Demo Mode!',
              })}
            </StyleTitle>
            <Space direction="vertical" size={6}>
              <Text>
                {formatMessage({
                  id: 'demo.modal.description1',
                  defaultMessage:
                    'Explore Genie AI with pre-loaded data to get a feel for the features.',
                })}
              </Text>
              <Text>
                {formatMessage({
                  id: 'demo.modal.description2',
                  defaultMessage: `Take a tour of Genie AI's capabilities.`,
                })}
              </Text>
            </Space>
            <Button style={{ margin: '8px 0' }} type="primary" onClick={onConfirm}>
              {formatMessage({
                id: 'demo.modal.action.start',
                defaultMessage: `Get started`,
              })}
            </Button>
          </Space>
          <StyledDivier />
          <Space direction="vertical" size={6}>
            <StyledLinkTextWrapper>
              {formatMessage({
                id: 'demo.modal.guide.description1',
                defaultMessage: `Would you like to check your advertising data in Genie AI?`,
              })}
            </StyledLinkTextWrapper>
            <StyledLinkTextWrapper>
              {formatMessage(
                {
                  id: 'demo.modal.guide.description2',
                  defaultMessage: `Please follow {link} and contact MoBagel.`,
                },
                {
                  link: (
                    <a target="_blank" href="https://ai-audience-tw.super.site/" rel="noreferrer">
                      {formatMessage({
                        id: 'demo.modal.guide.instruction',
                        defaultMessage: 'this instructions',
                      })}
                    </a>
                  ),
                },
              )}
            </StyledLinkTextWrapper>
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default TutorialModal;
