import { useCallback } from 'react';
import { history, useModel } from 'umi';

import { postLogout } from '@/services/auth';
import { pushResetSession } from '@/tracking/gtm/user';
import { trackResetSession } from '@/tracking/segment/user';

const useLogout = () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const logout = useCallback(async () => {
    await postLogout();
    if (initialState) {
      setInitialState({ ...initialState, currentUser: undefined });
    }

    pushResetSession();
    trackResetSession();
    // Note: There may be security issues, please note
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, initialState]);

  return logout;
};

export default useLogout;
