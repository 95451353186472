export const setStorageCompanyId = (companyId: string) => {
  localStorage.setItem('companyId', companyId);
};

export const getStorageCompanyId = (): string | null => {
  return localStorage.getItem('companyId');
};

// make sure company id is exist in user's companies
export function setupCompanyId(currentUser: API.User) {
  if (!currentUser?.companies) {
    return;
  }
  const selectCompanyId = getStorageCompanyId();
  // not set company id in local storage
  if (!selectCompanyId) {
    setStorageCompanyId(currentUser?.companies[0]?.id as string);
  } else {
    // check if company id is not exist in user's companies
    const isInvalidCompanyId = !currentUser?.companies.find(
      (company) => company.id === selectCompanyId,
    );
    if (isInvalidCompanyId) {
      setStorageCompanyId(currentUser?.companies[0]?.id as string);
    }
  }
}
