export function getLoginUrl() {
  return `/login`;
}

export const getSsoUrl = () => {
  // get corresponding sso url, for example:
  // smb-dev.mobagel.com -> sso-dev.mobagel.com
  // smb.localhost.com -> sso.localhost.com
  // smb-demo.mobagel.com -> sso-demo.mobagel.com
  const domains = window.location.host.split('.');
  const subDomain = domains[0].split('-');
  subDomain[0] = 'sso';
  domains.shift();
  return `${window.location.protocol}//${subDomain.join('-')}.${domains.join('.')}`;
};

export const getLandingPage = ({ useAia, useGenie }: { useAia?: boolean; useGenie?: boolean }) => {
  if (useAia) {
    return '/overview/traffic';
  } else if (useGenie) {
    return '/profile/connection';
  }
  return '/profile/setting';
};
