import { serviceName } from '@/utils/constant';
import { useEffect } from 'react';
import { history } from 'umi';

import { pushEventPageView } from '@/tracking/segment/commonEvent';

const useHistoryChange = () => {
  const sendPageView = () => {
    pushEventPageView(serviceName);
  };

  useEffect(() => {
    sendPageView();
    history.listen(() => {
      sendPageView();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default useHistoryChange;
