import dayjs from 'dayjs';

/**
 * This function uses for removing object when value is null or undefined.
 * If the value type is not object. it will directly return it.
 * @value { [string]: null | undefined }
 * @returns {}
 */
export const removeObjectValueUndefinedNull = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map(removeObjectValueUndefinedNull);
  }
  if (typeof value === 'object') {
    return Object.keys(value).reduce((obj, key) => {
      if (value[key] !== undefined && value[key] !== null) {
        if (
          typeof value === 'object' &&
          !(value[key] instanceof dayjs) &&
          !(value[key] instanceof Blob)
        ) {
          obj[key] = removeObjectValueUndefinedNull(value[key]);
        } else {
          obj[key] = value[key];
        }
      }
      return obj;
    }, {});
  }
  return value;
};
