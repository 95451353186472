import { Alert, Divider, Typography } from 'antd';
import { createGlobalStyle, styled } from 'styled-components';

const { Title, Text } = Typography;

export const GlobalFixedLayoutStyle = createGlobalStyle`
  #root {
    .ant-pro-layout .ant-layout-header.ant-pro-layout-header-fixed-header {
      top: 50px;
    }
    .ant-layout.ant-layout-has-sider {
      position: relative;
      top: 50px;
    }
    .ant-layout-sider.ant-pro-sider {
      position: fixed;
      top: 110px;
    }
  }
`;

export const GlobalFilterBlurStyle = createGlobalStyle`
  #root {
    filter: blur(10px);
  }
`;

export const StyledAlert = styled(Alert)`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 0;
  &.ant-alert {
    padding: 8px 16px;
    color: ${({ theme }) => theme.token.colorPrimary};
  }
`;

export const StyledLinkTextWrapper = styled(Text)`
  color: ${({ theme }) => theme.token.colorTextTertiary};
  & a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

export const StyledDivier = styled(Divider)`
  margin: 16px -24px;
  width: calc(100% + 48px);
`;

export const StyleTitle = styled(Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.token.colorPrimary};
  }
`;

export const StyledIcon = styled.div`
  color: ${({ theme }) => theme.token.colorPrimary};
`;
