import {
  getTrafficQualityMeta,
  getTrafficQualityMetrics,
  getTrafficQualityReport,
} from '@/services/trafficQuality';
import { useState } from 'react';

export default () => {
  const [metrics, setMetrics] = useState<API.TrafficQualityMetrics>();
  const [report, setReport] = useState<API.TrafficQualityReport>();
  const [meta, setMeta] = useState<API.TrafficQualityMeta>();

  const getMetrics = async () => {
    return getTrafficQualityMetrics().then((res) => {
      setMetrics(res);
      return res;
    });
  };

  const getReport = async (
    params: API.TrafficQualityReportGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getTrafficQualityReport(params, options).then((res) => {
      setReport(res);
      return res;
    });
  };

  const getMeta = async () => {
    return getTrafficQualityMeta().then((res) => {
      setMeta(res);
      return res;
    });
  };

  return {
    metrics,
    getMetrics,
    report,
    getReport,
    getMeta,
    meta,
  };
};
