import {
  getAvgPurchaseRevenueAPI,
  getItemBrandAPI,
  getItemPurchasedAPI,
  getOverviewAPI,
  getProductAPI,
  getPurchaseAPI,
  getSourceMediumAPI,
} from '@/services/revenue';
import { useState } from 'react';

export default () => {
  const [overview, setOverview] = useState<API.RevenueOverview>();
  const [purchase, setPurchase] = useState<API.RevenuePurchase>();
  const [avgPurchaseRevenue, setAvgPurchaseRevenue] = useState<API.RevenueAvgPurchaseRevenue>();
  const [itemPurchased, setItemPurchased] = useState<API.RevenueItemPurchased>();
  const [sourceMedium, setSourceMedium] = useState<API.RevenueSourceMedium>();
  const [itemBrand, setItemBrand] = useState<API.RevenueItemBrand>();
  const [product, setProduct] = useState<API.RevenueProduct>();

  const getOverview = async (
    params: API.RevenueOverviewGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getOverviewAPI(params, options).then((res) => {
      setOverview(res);
      return res;
    });
  };

  const getPurchase = async (
    params: API.RevenuePurchaseGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getPurchaseAPI(params, options).then((res) => {
      setPurchase(res);
      return res;
    });
  };

  const getAvgPurchaseRevenue = async (
    params: API.RevenueAvgPurchaseRevenueGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getAvgPurchaseRevenueAPI(params, options).then((res) => {
      setAvgPurchaseRevenue(res);
      return res;
    });
  };

  const getItemPurchased = async (
    params: API.RevenueItemPurchasedGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getItemPurchasedAPI(params, options).then((res) => {
      setItemPurchased(res);
      return res;
    });
  };

  const getSourceMedium = async (
    params: API.RevenueSourceMediumGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getSourceMediumAPI(params, options).then((res) => {
      setSourceMedium(res);
      return res;
    });
  };

  const getItemBrand = async (
    params: API.RevenueItemBrandGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getItemBrandAPI(params, options).then((res) => {
      setItemBrand(res);
      return res;
    });
  };

  const getProduct = async (
    params: API.RevenueProductGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getProductAPI(params, options).then((res) => {
      setProduct(res);
      return res;
    });
  };

  return {
    overview,
    getOverview,
    purchase,
    getPurchase,
    avgPurchaseRevenue,
    getAvgPurchaseRevenue,
    itemPurchased,
    getItemPurchased,
    sourceMedium,
    getSourceMedium,
    itemBrand,
    getItemBrand,
    product,
    getProduct,
  };
};
