import {
  getConversionDailyAPI,
  getConversionListAPI,
  getConversionSourceMediumAPI,
  getConversionUserJourneyAPI,
  getOverviewAPI,
} from '@/services/conversion';
import { audienceNameField } from '@/utils/constant';

import { useState } from 'react';

export default () => {
  const [conversionList, setConversionList] = useState<API.ConversionList>();
  const [conversionDailyNewUSer, setConversionDailyNewUser] = useState<API.ConversionDaily>();
  const [conversionDailyReturningUser, setConversionDailyReturningUser] =
    useState<API.ConversionDaily>();
  const [conversionUserJourney, setConversionUserJourney] = useState<API.ConversionUserJourney>();
  const [conversionSourceMedium, setConversionSourceMedium] =
    useState<API.ConversionSourceMedium>();
  const [overview, setOverview] = useState<API.RevenueOverview>();

  const getOverview = async (
    params: API.ConversionOverviewGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getOverviewAPI(params, options).then((res) => {
      setOverview(res);
      return res;
    });
  };

  const getConversionList = async (
    params: API.ConversionListGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getConversionListAPI(params, options).then((res) => {
      setConversionList(res);
      return res;
    });
  };

  const getConversionDailyNewUser = async (
    params: Omit<API.ConversionDailyGetParameters, 'audienceName'>,
    options?: API.RequestOptions,
  ) => {
    return getConversionDailyAPI(
      { ...params, audienceName: audienceNameField.mobagelNewUser },
      options,
    ).then((res) => {
      setConversionDailyNewUser(res);
      return res;
    });
  };

  const getConversionDailyReturningUser = async (
    params: Omit<API.ConversionDailyGetParameters, 'audienceName'>,
    options?: API.RequestOptions,
  ) => {
    return getConversionDailyAPI(
      { ...params, audienceName: audienceNameField.mobagelReturningUser },
      options,
    ).then((res) => {
      setConversionDailyReturningUser(res);
      return res;
    });
  };

  const getConversionUserJourney = async (
    params: API.ConversionUserJourneyGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getConversionUserJourneyAPI(params, options).then((res) => {
      setConversionUserJourney(res);
      return res;
    });
  };

  const getConversionSourceMedium = async (
    params: API.ConversionSourceMediumGetParameters,
    options?: API.RequestOptions,
  ) => {
    return getConversionSourceMediumAPI(params, options).then((res) => {
      setConversionSourceMedium(res);
      return res;
    });
  };

  return {
    getOverview,
    overview,
    conversionList,
    getConversionList,
    getConversionDailyNewUser,
    conversionDailyNewUSer,
    getConversionDailyReturningUser,
    conversionDailyReturningUser,
    getConversionUserJourney,
    conversionUserJourney,
    getConversionSourceMedium,
    conversionSourceMedium,
  };
};
