import { getCommonVariables } from '@/tracking/commonVariables';
import { identify, track } from './functions';

// reset segment track info
export const trackResetSession = () => {
  track('logout', { ...getCommonVariables() });
};

export const trackIdentifyUserLogin = () => {
  track('login', { ...getCommonVariables() });
};

// identify
export const identifyUserLogin = ({ userId, name, email }: EVENT.LoginInfo) => {
  identify(userId, {
    name,
    email,
  });
};

export const trackUserUpdateSetting = ({ name }: { name: string }) => {
  track('Changed Personal Info', { ...getCommonVariables(), name });
};
